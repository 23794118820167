// React Basic and Bootstrap
import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";

class AGB extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<React.Fragment>
            {/* breadcrumb */}
            <section className="bg-half-50 bg-light d-table w-100">
                <Container>
                    <Row className="mt-5 justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading">
                                <h4 className="title mb-0"> AGB </h4>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={9}>
                            <Card className="shadow border-0 rounded">
                                <CardBody>
                                    <h5 className="card-title">AGB</h5>
                                    <p className="text-muted">
                                        fibu3 ist ein Produkt von fibu3 GmbH und unterliegt nachfolgenden
                                        Bestimmungen. Die Bestimmungen können ohne Angaben von Gründen geändert
                                        werden.
                                    </p>

                                    <h5 className="card-title">Anwendbarkeit</h5>
                                    <p className="text-muted">
                                        Diese Allgemeinen Geschäftsbedingungen regeln die vertraglichen Beziehungen
                                        zwischen dem Kunden und der fibu3 GmbH. Die Allgemeinen
                                        Geschäftsbedingungen finden Anwendung auf sämtliche Vereinbarungen zwischen
                                        fibu3 GmbH und dem Kunden betreffend des von fibu3 GmbH angebotenen fibu3.
                                    </p>


                                    <h5 className="card-title">Inkrafttreten, Widerrufsrecht, Rücktritt
                                    </h5>
                                    <p className="text-muted">
                                        Sobald der Kunde eine Vereinbarung mit fibu3 GmbH bestätigt hat, tritt die
                                        entsprechende Vereinbarung unter Einbezug dieser Allgemeinen
                                        Geschäftsbedingungen in Kraft.
                                    </p>
                                    <p className="text-muted">
                                        fibu3 GmbH behält sich das Recht vor, jede durch einen Mitarbeiter oder einen
                                        Agenten der fibu3 GmbH oder jede über das Internet mit dem Kunden abgeschlossene
                                        Vereinbarung ohne Angabe eines Grundes und ohne Entschädigungsfolgen
                                        innerhalb von 14 Tagen aufzulösen.
                                    </p>
                                    <p className="text-muted">
                                        Im Fall einer vertragswidrigen, widerrechtlichen oder sittenwidrigen
                                        Benützung der Applikation fibu3 durch den Kunden kann fibu3 GmbH ohne
                                        Entschädigungsfolgen sofort vom Vertrag zurücktreten und die
                                        Leistungserbringung ohne vorgängige Mitteilung an den Kunden und ohne
                                        Entschädigungsfolgen einstellen.

                                    </p>
                                    <h5 className="card-title">Zugangsberechtigung
                                    </h5>
                                    <p className="text-muted">fibu3 GmbH erteilt dem Kunden Zugangsberechtigung zu fibu3
                                        mittels Benutzeridentifikation und Passwort. Für fibu3 gilt als Kunde
                                        diejenige Person, welche die Benutzeridentifikation und Passwort verwendet,
                                        unabhängig davon, ob diese Person tatsächlich die Zugriffsberechtigung
                                        besitzt.
                                    </p>
                                    <p className="text-muted">
                                        Die Applikation fibu3 läuft auf den Servern der fibu3 GmbH. Mit entsprechender
                                        Vereinbarung, kann fibu3 auch auf der Infrastruktur des Kunden betrieben
                                        werden.
                                    </p>
                                    <h5 className="card-title">Preise und Zahlungsbedingungen
                                    </h5>
                                    <p className="text-muted">
                                        Falls nicht ausdrücklich anders vereinbart, gilt für die Berechnung der Preise
                                        die jeweils gültige Preisliste von fibu3 GmbH für fibu3. Diese Preislisten sind
                                        jederzeit unter den entsprechenden Applikationen im Internet abrufbar.
                                    </p>
                                    <p className="text-muted">
                                        fibu3 GmbH behält sich vor, die Preise der Markt- und/oder Preisentwicklung
                                        anzupassen. Diese Preisanpassungen werden, falls nicht ausdrücklich anders
                                        vereinbart, auch auf laufende Verträge angewendet. Die Preise verstehen sich,
                                        falls nicht ausdrücklich anders vereinbart, netto in Schweizer Franken inklusive
                                        Mehrwertsteuer.
                                    </p>
                                    <p className="text-muted">
                                        Es gelten die in der Schweiz üblichen Zahlungsfristen nach Rechnungsstellung.
                                        Bezahlt der Kunde nicht innerhalb der Zahlungsfrist, so kann fibu3 GmbH die
                                        Vereinbarung frist- und entschädigungslos ohne Mahnung auflösen.
                                    </p>
                                    <p className="text-muted">
                                        Im Falle von nicht fristgerecht bezahlten Zahlungen während laufender
                                        Vertragsdauer hat fibu3 GmbH zudem das Recht, den Zugang des Kunden zu fibu3 zu
                                        sperren. Mit vollständiger Bezahlung aller offenen Rechnungen wird die Sperrung
                                        wieder aufgehoben. Der Kunde hat in diesem Fall kein Anrecht auf eine
                                        Verlängerung der vereinbarten Vertragsdauer um die Dauer der Sperrung.
                                    </p>
                                    <p className="text-muted">
                                        Desweiteren gelten die in der Schweiz üblichen Mahnungsfristen und Konditionen
                                        bei Zahlungsverzug.
                                    </p>
                                    <h5 className="card-title">Immaterialgüterrechte</h5>
                                    <p className="text-muted">
                                        Sämtliche Urheberrechte und Marken sowie Know-how an den Applikationen von fibu3
                                        stehen ausschliesslich fibu3 GmbH zu. Soweit die vertragsgemässe Nutzung der
                                        Applikation durch den Kunden Nutzungsrechte an Urheberrechten, Marken und/oder
                                        Know-how von fibu3 GmbH voraussetzt, werden diese dem Kunden nicht-exklusiv,
                                        unübertragbar und im benötigten Umfang für die Dauer der entsprechenden
                                        Vereinbarung durch fibu3 GmbH erteilt.
                                    </p>
                                    <p className="text-muted">
                                        Die dem Kunden eingeräumten Nutzungsrechte an fibu3 sind persönlich und nicht
                                        übertragbar. Sämtliche in fibu3 erstellten Unterlagen und Informationen sind
                                        Eigentum des Kunden.
                                    </p>
                                    <h5 className="card-title">Bewirtschaftung und Weiterentwicklung der Applikation
                                    </h5>
                                    <p className="text-muted">
                                        Der Betrieb und die Bewirtschaftung von fibu3 erfolgt durch fibu3 GmbH. fibu3
                                        GmbH ist
                                        berechtigt, zur Erfüllung ihrer Leistungspflichten Dritte beizuziehen. fibu3
                                        GmbH ist
                                        bestrebt, den Betrieb der Applikationen möglichst störungsfrei anzubieten und
                                        Unterbrechungen für die Behebung von Störungen, Wartungsarbeiten, Einführungen
                                        neuer Technologien und dergleichen kurz zu halten. Die technische
                                        Weiterentwicklung liegt allein im Ermessen der fibu3 GmbH.
                                    </p>
                                    <p className="text-muted">
                                        Weiterentwicklungen aufgrund von Kundenbedürfnissen fliessen die normale
                                        Releaseplanung ein. Individuelle durch den Kunden in Auftrag gegebene
                                        Weiterentwicklungen können ebenfalls in die normale Releaseplanung einfliessen
                                        und sind nicht Eigentum des Kunden.
                                    </p>
                                    <h5 className="card-title">Datenschutz / Publikationsnetzwerk
                                    </h5>
                                    <p className="text-muted">
                                        fibu3 GmbH kann die Informationen von fibu3 anonym für eigene Zwecke wie zum
                                        Beispiel
                                        Statistiken verwenden.
                                    </p>
                                    <p className="text-muted">
                                        fibu3 GmbH behandelt die ihr überlassenen Daten streng vertraulich und schützt
                                        die
                                        Interessen des Kunden. Persönliche Daten werden weder an Dritte verkauft noch
                                        weitergegeben. fibu3 GmbH bemüht sich in angemessener Weise, die Datenbanken von
                                        fibu3
                                        vor fremden Zugriffen, Verlusten, Missbräuchen oder vor Fälschungen zu schützen.
                                    </p>
                                    <h5 className="card-title">Rechte und Pflichten des Kunden
                                    </h5>
                                    <p className="text-muted">
                                        Der Kunde verpflichtet sich, die Applikationen fibu3 von fibu3 GmbH gesetzes-
                                        und
                                        vertragsgemäss zu nutzen.
                                    </p>
                                    <h5 className="card-title">Gewährleistung</h5>
                                    <p className="text-muted">
                                        Soweit gesetzlich zulässig schliesst fibu3 GmbH jede Haftung für
                                        Mängelfolgeschäden
                                        aus.
                                    </p>
                                    <h5 className="card-title">Risiko</h5>
                                    <p className="text-muted">
                                        Der Kunde trägt sämtliche Risiken, die infolge von Manipulationen an seinem
                                        EDV-System, infolge von Funktionsstörungen seines EDV-Systems und/oder infolge
                                        missbräuchlicher Verwendung von Zugriffsberechtigungen auftreten können.
                                    </p>
                                    <h5 className="card-title">Haftung
                                    </h5>
                                    <p className="text-muted">
                                        Der Kunde ist vollumfänglich für den Inhalt der von ihm an fibu3 GmbH
                                        übermittelten
                                        Daten verantwortlich. fibu3 GmbH lehnt jede Haftung für die Inhaltsgebung des
                                        Kunden
                                        ab.
                                    </p>
                                    <p className="text-muted">
                                        fibu3 GmbH haftet nur bei Absicht oder grober Fahrlässigkeit. In keinem Fall
                                        haftet
                                        fibu3 GmbH für Folgeschäden und entgangenen Gewinn. Kann fibu3 GmbH trotz aller
                                        Sorgfalt
                                        aufgrund von höherer Gewalt wie beispielsweise Naturereignissen, kriegerischen
                                        Auseinandersetzungen, Streik, unvorhergesehenen behördlichen Restriktionen,
                                        technischen Störungen, welche dem Verantwortungsbereich Dritter zuzuordnen sind,
                                        ihren vertraglichen Verpflichtungen nicht nachkommen, besteht für die Dauer des
                                        Ereignisses kein Anspruch des Kunden auf Vertragserfüllung.
                                    </p>
                                    <p className="text-muted">
                                        fibu3 GmbH haftet nicht für den Missbrauch des Internets und damit verbundene
                                        Schädigungen des Kunden durch Dritte, für Sicherheitsmängel und Störungen der
                                        Fernmeldenetze von Dritten und des Internets sowie für Betriebsunterbrüche und
                                        Störungen der Applikationen fibu3.
                                    </p>
                                    <h5 className="card-title">Abtretungsverbot</h5>
                                    <p className="text-muted">
                                        Der Kunde darf das Vertragsverhältnis mit fibu3 GmbH nicht ohne schriftliche
                                        Einwilligung von fibu3 GmbH auf einen Rechtsnachfolger übertragen, ebenso wenig
                                        dürfen
                                        Forderungen aus dem Vertragsverhältnis mit fibu3 GmbH ohne schriftliche
                                        Einwilligung
                                        von fibu3 GmbH an Dritte abgetreten werden.
                                    </p>
                                    <h5 className="card-title">Allgemeine Bestimmungen
                                    </h5>
                                    <p className="text-muted">
                                        Änderungen und Ergänzungen von schriftlichen Vereinbarungen, ausserhalb der
                                        Allgemeinen Geschäftsbedingungen, der entsprechenden Nutzungsbestimmungen und
                                        der entsprechenden Insertionsbestimmungen, bedürfen zu ihrer Gültigkeit der
                                        Schriftform unter Zustimmung beider Parteien. Dies gilt auch für einen
                                        allfälligen Verzicht auf das Schriftformerfordernis.
                                    </p>
                                    <p className="text-muted">
                                        Sollte eine Bestimmung einer Vereinbarung ungültig oder undurchführbar sein, so
                                        fällt diese nur im Ausmass ihrer Ungültigkeit oder Undurchführbarkeit dahin und
                                        ist im übrigen durch eine der unwirksamen oder undurchführbaren Regelung
                                        wirtschaftlich möglichst nahekommende Ersatzbestimmung zu ersetzen. Allfällige
                                        Lücken der entsprechenden Vereinbarung sind durch Regelungen auszufüllen, welche
                                        dem am nächsten kommen, was die Parteien nach dessen Sinn und Zweck vereinbart
                                        hätten, wenn sie an den betreffenden Punkt beim Abschluss der entsprechenden
                                        Vereinbarung gedacht hätten.
                                    </p>
                                    <h5 className="card-title">Anwendbares Recht und Gerichtsstand
                                    </h5>
                                    <p className="text-muted">
                                        Dieser Vertrag untersteht ausschliesslich schweizerischem Recht, unter
                                        Ausschluss des internationalen Kollisionsrechts. Ausschliesslicher Erfüllungsort
                                        und Gerichtsstand ist am Sitz der fibu3 GmbH. fibu3 GmbH ist auch berechtigt,
                                        den Kunden an
                                        seinem Sitz/Wohnsitz zu belangen.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>);
    }
}

export default AGB;
