// React Basic and Bootstrap
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Alert,
    Form,
    Input,
    Label,
    Card,
    CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactvisible: false,
            errorVisible: false,
            sent: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendMail.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const urlSearchParams = new URLSearchParams(formData);
        this.sendPostRequest(urlSearchParams);
    }

    sendPostRequest(formData) {
        fetch("/rest/api/v1/contact", {
            method: "POST",
            body: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((response) => {
                if (response.ok) {
                    this.setState({contactvisible: true, sent: true});
                } else {
                    this.setState({errorVisible: true, sent: true});
                }
            })
            .catch((error) => {
                this.setState({errorVisible: true, sent: true});
            });
    }

    sendMail() {
        window.location.href = "mailto:info#fibu3.ch";
    }

    render() {
        return (
            <React.Fragment>
                <section className="bg-half-50 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> Kontaktieren Sie uns</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>


                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col
                            lg={5}
                            md={{size: 6, order: 1}}
                            xs={{order: 2}}
                            className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                        >
                            <Card className="custom-form rounded shadow border-0">
                                <CardBody>
                                    <div id="message"></div>
                                    <Alert
                                        color="primary"
                                        isOpen={this.state.contactvisible}
                                        toggle={() => {
                                            this.setState({
                                                contactvisible: !this.state.contactvisible,
                                            });
                                        }}
                                    >
                                        Vielen Dank für Ihre Nachricht.
                                    </Alert>
                                    <Alert
                                        color="danger"
                                        isOpen={this.state.errorVisible}
                                        toggle={() => {
                                            this.setState({
                                                errorVisible: !this.state.errorVisible,
                                            });
                                        }}
                                    >
                                        Etwas ist schief gegangen. Versuchen Sie es später oder senden Sie uns eine
                                        Email.
                                    </Alert>
                                    <Form
                                        method="post"
                                        onSubmit={this.handleSubmit}
                                        name="contact-form"
                                        id="contact-form"
                                    >
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        name="name"
                                                        disabled={this.state.sent}
                                                        id="name"
                                                        type="text"
                                                        className="form-control ps-5"
                                                        placeholder="Name"
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="mail"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        name="email"
                                                        disabled={this.state.sent}
                                                        id="email"
                                                        type="email"
                                                        className="form-control ps-5"
                                                        placeholder="Email"
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Nachricht</Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="message-circle"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <textarea
                                                        name="message"
                                                        disabled={this.state.sent}
                                                        id="message"
                                                        rows="4"
                                                        className="form-control ps-5"
                                                        placeholder="Nachricht"
                                                    ></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" className="text-center">
                                                <div className="d-grid">
                                                    <input
                                                        disabled={this.state.sent}
                                                        type="submit"
                                                        id="submit"
                                                        name="send"
                                                        className="submitBnt btn btn-primary btn-block"
                                                        value="Nachricht senden"
                                                    />
                                                </div>
                                                <div id="simple-msg"></div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={7} md={{size: 6, order: 2}} xs={{order: 1}}>
                            <div className="title-heading ms-lg-4">
                                <h4 className="mb-4">Kontaktinformationen</h4>
                                <p className="text-muted">Haben Sie Fragen oder Anregungen? Wir freuen uns auf Ihre
                                    Nachricht.
                                </p>
                                <div className="d-flex contact-detail align-items-center mt-3">
                                    <div className="icon">
                                        <i>
                                            <FeatherIcon
                                                icon="mail"
                                                className="fea icon-m-md text-dark me-3"
                                            />
                                        </i>
                                    </div>
                                    <div className="flex-1 content">
                                        <h6 className="title fw-bold mb-0">Email</h6>
                                        <Link
                                            to="#"
                                            onClick={this.sendMail}
                                            className="text-primary "
                                        >
                                            info@fibu3.ch
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Contact;