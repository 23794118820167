// React Basic and Bootstrap
import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";


class Impressum extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {/* breadcrumb */}
                <section className="bg-half-50 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> Impressum </h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={9}>
                                <Card className="shadow border-0 rounded">
                                    <CardBody>
                                        <h5 className="card-title">Umsetzung und Konzeption</h5>
                                        <p className="text-muted">
                                            Die Software und alle zugehörigen Dienstleistungen werden von der Firma
                                            fibu3 GmbH erbracht.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Impressum;
