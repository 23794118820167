import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

//Import Images
import KP from '../../assets/images/learning/kp.png';
import kp_edit from '../../assets/images/learning/kp-edit.png';
import newaccount from '../../assets/images/learning/newaccount.png';
import ER from '../../assets/images/learning/erfolgsrechnung.jpg';
import bilanz from '../../assets/images/learning/bilanz.jpg';
import bilanz1 from '../../assets/images/learning/bilanz1.png';
import buchung1 from '../../assets/images/learning/buchung1.png';
import konti from '../../assets/images/learning/konti.jpg';
import aktivtausch from '../../assets/images/learning/aktivtausch.jpg';
import mwst1 from '../../assets/images/learning/mwst1.png';

class Community extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            listData1: [
                {id: '#kontenrahmen', line: 'Den passenden Kontenrahmen auswählen'},
                {id: '#bilanzer', line: 'Bilanz und Erfolgsrechnung'},
                {id: '#doppeltefibu', line: 'Doppelte Buchhaltung / einfache Buchungen'},
                {id: '#aktivtausch', line: 'Aktivtausch'},
                {id: '#mwst1', line: 'Mehrwertsteuer buchen'},
            ],

            listData2: [
                {id: '#mwst1', line: 'Mehrwertsteuer buchen'},
                {id: '#mwst2', line: 'Mehrwertsteuersätze verwalten'},
                {id: '#mwst3', line: 'Mehrwertsteuer abrechnen'},
            ],

            listData3: [
                {id: 1, line: 'Erste Schritte in fibu3'},
                {id: 2, line: 'Einen Beleg buchen'},
                {id: 3, line: 'Einen Verkauf buchen'},
                {id: 4, line: 'Einen Einkauf buchen'},
                {id: 5, line: 'Einen Debitor buchen'},
                {id: 6, line: 'Einen Kreditor buchen'},
            ],

            listData4: [
                {id: 1, line: 'Jahresabschluss'},
                {id: 2, line: 'Mandanten erstellen'},
                {id: 3, line: 'Fremdwährungen buchen'},
            ],
        };
    }

    render() {
        return (
            <React.Fragment>

                <section className="bg-half-50 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0">Buchhaltung lernen</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>
                <section className="section">
                    <Container>
                        <Row>
                            <Col lg={4} md={6} xs={12}>
                                <h5>Buchhaltungs Einmaleins</h5>
                                <ul className="list-unstyled mt-4 mb-0">
                                    {this.state.listData1.map((item, key) => (
                                        <li key={key} className="mt-2">
                                            <a href={item.id} className="text-muted">
                                                <i className="mdi mdi-arrow-right text-primary me-2"></i>
                                                {item.line}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Col>

                        </Row>
                    </Container>
                </section>

                <section id="kontenrahmen" className="section">
                    <Container>
                        <div>
                            <h2>Den passenden Kontenrahmen auswählen</h2>
                            <p>Welches ist der passende Kontenrahmen für meine Buchhaltung? Der Kontenrahmen ist
                                die Grundlage
                                Ihrer Buchhaltung und bildet bildet alle Konten Ihrer Buchhaltung ab. In fibu3
                                können Sie zwischen drei verschiedenen Kontenrahmen wählen.
                                <ul>
                                    <li>Der KMU Kontenrahmen eignet sich in den meisten Fällen.</li>
                                    <li>Der Käfer Kontenrahmen ist veraltet und ist für neue Buchhaltungen nicht mehr
                                        zu empfehlen.
                                    </li>
                                    <li>Sie können den Kontenrahmen mit fibu3 Ihren individuellen Bedürfnissen
                                        anpassen.
                                    </li>
                                </ul>
                            </p>
                            <p></p>
                            <img src={KP}/>
                            <p></p>
                            <p>Wenn Sie sich das erste Mal bei Fibu3 anmelden, können Sie den Kontenrahmen auswählen.
                                Das System legt alle Konten automatisch an und Sie können direkt mit Ihrer Buchhaltung starten.
                                Eine erste Testbuchung wird automatisch erstellt; diese können Sie jederzeit löschen.
                                Wenn Sie den Kontenrahmen anpassen möchten, klicken Sie auf "Buchhaltung > Kontenrahmen".</p>

                            <p></p>
                            <p>Hier können Sie:</p>
                            <ul>
                                <li>Neues Konto anlegen</li>
                                <li>Konto editieren (z.B. Startwerte eintragen)</li>
                                <li>Konto löschen</li>
                            </ul>
                            <p></p>

                            <img src={newaccount}/>
                            <p></p>
                            <p>Im obigen Beispiel haben wir ein neues Bankkonto angelegt. Sobald Sie die Änderungen
                                gespeichert haben,
                                können Sie direkt auf das neue Konto buchen.</p>

                        </div>
                    </Container>
                </section>

                <section id="bilanzer" className="section">
                    <Container>
                        <div>
                            <h2>Bilanz</h2>
                            <p>Die Bilanz stellt die Vermögenswerte und die Finanzierung eines Unternehmens dar
                                (Aktiva und Passiva).</p>
                            <img src={bilanz}/>
                            <h3>Aktiva</h3>
                            <p>Hier finden Sie Ihre Vermögenswerte. Zum Beispiel Ihre Bankkonten, den Wert Ihrer
                                Fahrzeuge. Die Aktiv-Seite gibt Auskunft darüber, wie das Kapital des Unternehmens
                                investiert worden ist. Eine Soll-Buchung stellt einen Zugang, eine Haben-Buchung einen
                                Abgang dar.</p>
                            <h3>Passiva</h3>
                            <p>Auf der Passiv-Seite sehen Sie, woher die finanziellen Mittel der Unternehmung kommen.
                                Dies kann Eigenkapital (z.B. Stammkapital) oder Fremdkapital (z.B. ein Kredit) sein.
                                Eine Soll-Buchung stellt einen Abgang, eine Haben-Buchung einen Zugang dar.</p>
                        </div>

                        <div>
                            <h2>Erfolgsrechnung</h2>

                            <p>Die Erfolgsrechnung stellt die Ausgaben und Einnahmen Ihres Unternehmens dar. Sie wird
                                dazu verwendet um den Gewinn/Verlust zu berechnen. Der Gewinn bzw. der Verlust
                                lässt sich einfach aus der Differenz der Einnahmen und Ausgaben berechnen.</p>
                            <img src={ER}/>
                            <h3>Aufwand</h3>
                            <p>Geldabflüsse zum Beispiel für Rechnungen oder die Raummiete werden als
                                Aufwände gebucht. Diese Seite stellt den Wertverzehr Ihres Unternehmens dar. Eine
                                Soll-Buchung steigert den Aufwand, wohingegen eine Haben-Buchung den Aufwand
                                mindert.</p>
                            <h3>Ertrag</h3>
                            <p>Einnahmen welche z.B. durch den Verkauf von Gütern oder Dienstleistungen entstehen,
                                werden als Erträge verbucht. Diese Seite stellt den Wertzuwachs Ihres Unternehmens
                                dar. Eine Soll-Buchung vermindert den Ertrag, eine Haben-Buchung erhöht den
                                Ertrag.</p>

                        </div>
                    </Container>
                </section>

                <section id="doppeltefibu" className="section">
                    <Container>
                        <div>
                            <h2>Doppelte Buchhaltung</h2>
                            <p>Bei der doppelten Buchhaltung wird jeder Geschäftsfall auf zwei Konten verbucht.
                                Einmal im Soll und einmal im Haben.
                                Als einfaches Beispiel kann man sich vorstellen, dass ein Unternehmen eine Maschine im
                                Wert von CHF 5'000.00 über das Bankkonto bezahlt.
                                Dies bedeutet nun, dass wir eine neue Maschine besitzen und im Gegenzug das Bankguthaben
                                vermindert wird.</p>

                            <ul>
                                <li>Maschine: +5'000.00</li>
                                <li>Bank: -5'000.00</li>
                            </ul>
                            <p>Der Buchungssatz für diese Transaktion lautet: Maschine an Bank 5'000,00. In Fibu3 können Sie dies wie folgt buchen:</p>
                            <img src={buchung1}/>
                            <p>Durch diese Buchung verändert sich die Bilanz und wir sehen, dass wir neu Maschinen
                                im Wert von CHF 5'000.00 besitzen.</p>
                            <img src={bilanz1}/>
                            <p></p>
                            <h3>Einige weitere Beispiele</h3>
                            <p>Sie zahlen die Raummiete von CHF 540.00 über das Bankkonto.</p>
                            <ul>
                                <li>Raumiete (Aufwand) +540.00 (Soll-Buchung)</li>
                                <li>Bank: -540.00 (Haben-Buchung)</li>
                            </ul>
                            <p>Der Buchungssatz für diese Transaktion lautet: Raummiete an Bank 540,00.</p>

                            <p>Sie haben einem Kunden Dienstleistungen im Wert von CHF 24'000.00 verkauft und ihm
                                hierfür eine Rechnung geschickt.</p>
                            <ul>
                                <li>Debitoren (offene Forderungen von Kunden) +24'000.00 (Soll-Buchung)</li>
                                <li>Warenverkauf: +24'000.00 (Haben-Buchung)</li>
                            </ul>
                            <p>Der Buchungssatz für diese Transaktion lautet: Debitoren an Warenverkauf 24'000,00.</p>

                            <h3>Zugang oder Abgang</h3>
                            <p>Je nachdem, ob es sich um ein Aktiv-, Passiv-, Aufwand- oder Ertragskonto
                                handelt, ändert sich die Bedeutung einer Buchung in Soll/Haben. Eine Buchung
                                besteht immer aus einer Soll- und einer Haben-Buchung.</p>
                            <img src={konti}/>

                        </div>
                    </Container>
                </section>

                <section id="aktivtausch" className="section">
                    <Container>
                        <div>
                            <h2>Aktivtausch</h2>
                            <p>Eine Buchung kann auch zwei Aktivkonten betreffen, in diesem Fall spricht man von einem
                                Aktivtausch und ist im Endeffekt nichts weiter als eine Umschichtung.
                                So könnte man sich vorstellen, Geld vom Bankkonto auf das Postkonto
                                zu überweisen. </p>
                            <img src={aktivtausch}/>
                        </div>
                    </Container>
                </section>

                <section id="mwst1" className="section">
                    <Container>
                        <div>
                            <h2>Mehrwertsteuer</h2>
                            <p>Viele Unternehmen sind Mehrwertsteuerpflichtig und müssen auf den Umsatz die
                                Mehrwertsteuer bezahlen (Umsatzsteuer). Die Mehrwertsteuer wird in der Regel den Kunden
                                weiterbelastet.
                                Auch Unternehmen beziehen Waren und Dienstleistungen, welche der Mehrwertsteuer
                                unterliegen. Als Unternehmen dürfen Sie die an Sie weiterbelasteten Steuern als
                                sogenannte Vorsteuern vom Ihrer Schuld abziehen. Im Endeffekt Schulden Sie dem Bund die
                                Differenz aus Umsatzsteuer und Vorsteuer.
                                Viele Buchhaltungsprogramme unterstützen Sie bei der Mehrwertsteuer, so auch
                                fibu3.</p>

                            <h3>Beispiel 1</h3>
                            <ul>
                                <li>Sie beziehen Waren zum Preis von CHF 108.00 inkl. 8% MwSt.</li>
                                <li>Der Verkäufer der Ware muss dem Bund 8% des Umsatzes abliefern, in diesem Fall
                                    CHF 8.00, der Umsatz beträgt CHF 100.00. Die Steuer belastet er 1:1 an Sie
                                    weiter, weshalb Sie CHF 108.00 bezahlen.
                                </li>
                                <li>Sie möchten die Ware zum Preis von CHF 200.00 weiterverkaufen. Auch Sie
                                    müssen auf den Umsatz 8% MwSt. abliefern. In diesem Fall CHF 16.00.
                                </li>
                                <li>Ein Kunde kauft Ihr Produkt also inkl. MwSt. für CHF 216.00, da Sie die MwSt.
                                    weiterbelasten.
                                </li>
                                <li>Ihre Umsatzsteuer aus dieser Transaktion beträgt also CHF 16.00, welche Sie
                                    abliefern müssen.
                                </li>
                                <li>Ihre Vorsteuer aus dem Einkauf beträgt CHF 8.00, welche Sie von Ihrer Schuld
                                    abziehen können.
                                </li>
                                <li>CHF 16.00 - CHF 8.00 = CHF 8.00 -> soviel beträgt Ihre MwSt-Schuld in diesem
                                    Beispiel.
                                </li>
                            </ul>

                            <p>Nun zu den eigentlichen Buchungen in der Finanzbuchhaltung.</p>
                            <ul>
                                <li className="font-weight-bold">Der Einkauf der Ware</li>
                                <li>Warenaufwand an Bank CHF 100.00</li>
                                <li>MwSt-Vorsteuer an Bank CHF 8.00</li>
                                <li className="font-weight-bold">Der Verkauf der Ware</li>
                                <li>Debitoren an Warenverkauf CHF 200.00</li>
                                <li>Debitoren an Mwst-Umsatz CHF 16.00</li>
                            </ul>

                            <h3>Beispiel 2 - Verkauf nach Vereinnahmung</h3>
                            <ul>
                                <li>Sie verkaufen Waren zu CHF 108.00 inkl. 8% MwSt und haben als Verrechnungsart "nach
                                    Vereinnahmung" mit der ESTV abgemacht.
                                </li>
                            </ul>

                            <p>Buchungen in der Finanzbuchhaltung.</p>
                            <ul>
                                <li className="font-weight-bold">Der Verkauf der Ware</li>
                                <li>Debitoren an Warenertrag CHF 100.00</li>
                                <li className="font-weight-bold">Die Bezahlung der Ware</li>
                                <li>Bank an Debitoren CHF 108.00</li>
                                <li>Debitoren an Mwst-Umsatz CHF 8.00</li>
                            </ul>

                            <p>Je nach Ware oder Dienstleistung welche Sie beziehen gelten unterschiedliche
                                Mehrwertsteuersätze. Bitte beachten Sie, dass Sie die für Ihr Unternehmen
                                korrekten Sätze verwenden.</p>
                            <h3>Beispiel mit fibu3</h3>
                            <p>Mit fibu3 lassen sich Buchungen mit MwSt ganz einfach buchen. Es ist nur eine Buchung
                                nötig und Sie müssen die MwSt nicht separat eintippen. Sie können den
                                gesamten Betrag inkl. MwSt eintippen und wählen den zu verwendenden Satz.</p>
                            <img src={mwst1}/>

                        </div>
                    </Container>
                </section>


            </React.Fragment>
        );
    }
}

export default Community;



